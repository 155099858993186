/* --- Global --- */
import {Helmet} from 'react-helmet';

/* --- Local --- */
import wave from '@assets/images/bump.png';
import OMD from '@assets/images/omd-icon.jpg';

// import {FormDeployFortmaticContract} from '@forms';
import {FortmaticLogin, GithubLogin} from '@components';
import {QuestPreview} from '@queries';

import {FormClaimEth} from '@forms';
import {QuestCard, FortmaticLoginQuestCard} from '@components';
import questListConsenQuest from '@data/questListConsenQuest';
import questListMillionDevs from '@data/questListMillionDevs';

/* --- Component --- */
const PageHome = props => {
  return (
    <>
      <SearchEngineOptimization />
      <Showcase />
      <HackerInformation />
      <Featured />
    </>
  );
};

const Showcase = props => {
  return (
    <Atom.Flex
      center
      column
      sx={{
        color: 'white',
        // minHeight: [0, 0, '80vh'],
        py: [6],
        // pb: 7,
        zIndex: 1000,
      }}>
      <Atom.BackgroundGradient gradient="bluePurple" direction={0} />
      <Atom.Absolute sx={{bottom: 0, left: 0, right: 0}}>
        <Atom.Image src={wave} sx={{height: 130, width: '100%'}} />
      </Atom.Absolute>
      <Atom.Container>
        <Atom.Flex
          center
          co
          sx={{
            flexDirection: ['column', 'column', 'column'],
          }}>
          {/* Left : Region */}
          <Atom.Box sx={{flex: 2, textAlign: 'center'}}>
            <Atom.Heading
              giga
              sx={{
                fontSize: [5, 5, 5, 6],
              }}>
              <Atom.Span sx={{fontWeight: 300}}>Ethereum Quests</Atom.Span>
              <br />
              <Atom.Span
                sx={{
                  fontSize: [5, 5, 5],
                }}>
                Pick a Journey. Get Rewarded.
              </Atom.Span>
            </Atom.Heading>
            <Atom.Flex alignCenter sx={{mt: 4}}></Atom.Flex>
          </Atom.Box>
          <Atom.Flex
            alignCenter
            sx={{
              flexDirection: ['column', 'column', 'row'],
              mt: 4,
            }}>
            <Molecule.Link to="/adventure/consensys" sx={{mr: [0, 0, 3]}}>
              <Atom.Button tag sx={{p: 4}}>
                <Atom.Flex center column>
                  <Atom.Flex alignCenter>
                    <Atom.Image
                      sx={{
                        borderRadius: 9999,
                        width: 52,
                      }}
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSymm752bT_zWHbWNoZZsHOgrfQslCfjLp7HuCco7dNezKp9K_2NQ&s"
                    />
                    <Atom.Span xl sx={{mx: 2}}>
                      ConsenSys Adventure
                    </Atom.Span>
                  </Atom.Flex>
                  <Atom.Span sm sx={{mt: 2}}>
                    0.1 ETH Reward to experience Developer Products
                  </Atom.Span>
                </Atom.Flex>
              </Atom.Button>
            </Molecule.Link>
            {/* OMD Quest */}
            <Molecule.Link to="/adventure/1milliondevs" sx={{ml: [0, 0, 3]}}>
              <Atom.Button tag sx={{p: 4, textAlign: 'center'}}>
                <Atom.Flex center column>
                  <Atom.Flex alignCenter>
                    <Atom.Image
                      sx={{
                        borderRadius: 9999,
                        width: 52,
                      }}
                      src={OMD}
                    />
                    <Atom.Span xl sx={{mx: 2}}>
                      1MillionDevs Adventure
                    </Atom.Span>
                  </Atom.Flex>
                  <Atom.Span sm sx={{mt: 2}}>
                    Limited Edition onemilliondevs.eth sub-domain
                  </Atom.Span>
                </Atom.Flex>
              </Atom.Button>
            </Molecule.Link>
          </Atom.Flex>
          {/* Right : Region */}
          <Atom.Box
            sx={{
              flex: 1,
              mt: 4,
            }}>
            {/* <FormStartProject /> */}
          </Atom.Box>
        </Atom.Flex>
      </Atom.Container>
    </Atom.Flex>
  );
};

const HackerInformation = props => {
  return (
    <Atom.Container>
      <Atom.Flex
        alignCenter
        card
        sx={{flexDirection: ['column', 'column', 'row']}}>
        <Atom.Flex column sx={{flex: 1, p: 4}}>
          <Atom.Heading as="h3" xxl sx={{textAlign: 'center'}}>
            ETHDenver Hackers
          </Atom.Heading>
          <Atom.Heading as="h5" md sx={{textAlign: 'center'}}>
            Free ETH for Mainnet Launch
          </Atom.Heading>
          <Atom.Flex
            center
            sx={{
              flex: 1,
              flexDirection: ['column', 'column', 'row'],
              p: 4,
              textAlign: 'center',
            }}>
            <GithubLogin />
            <FortmaticLogin />
          </Atom.Flex>
          <Atom.Paragraph
            sx={{
              display: ['none', 'none', 'block'],
              textAlign: 'center',
            }}>
            <strong>
              Ready to take your EthDenver hack to the next level?
            </strong>{' '}
            The 1MillionDeveloper Stewards are sponsoring every hackers launch
            to mainnet! <em>Impress those judges even more!</em>
          </Atom.Paragraph>
        </Atom.Flex>
        <Atom.Flex
          center
          column
          sx={{flex: 1, p: [0, 0, 4], textAlign: 'center'}}>
          <Atom.Paragraph lg sx={{textAlign: 'center'}}>
            Claim your <strong>FREE</strong> Ξ today.
          </Atom.Paragraph>
          <FormClaimEth />
        </Atom.Flex>
      </Atom.Flex>
    </Atom.Container>
  );
};

const Featured = props => {
  return (
    <Atom.Box>
      <Atom.Container
        sx={{
          pt: 5,
          p: 4,
          maxWidth: ['100%', '100%', 550, 1020],
          zIndex: 1000,
        }}>
        <Atom.HorizontalRule
          sx={{
            mt: 4,
            mb: 4,
            width: 50,
            mx: 'auto',
          }}
        />

        <Atom.Flex
          alignCenter
          column
          sx={{
            flexDirection: ['column', 'column', 'row'],
          }}></Atom.Flex>
        {/* <ConsenQuestList /> */}

        {/* Quest Preview */}
        <Atom.Flex sx={{flexWrap: 'wrap'}}>
          <QuestPreview />
        </Atom.Flex>
        {/* Quest Preview : Old */}
        {/* <MillionDevsQuestList /> */}
      </Atom.Container>
    </Atom.Box>
  );
};

const stylesQuestCard = {
  mx: ['0', '0', '1.5%', '1.5%'],
  mb: 4,
  width: ['100%', '100%', '47%', '30%'],
};

const ConsenQuestList = props => {
  return (
    <>
      <Atom.Box>
        <Atom.Heading
          as="h3"
          xxl
          sx={{
            color: 'blue',
            textAlign: 'center',
            pb: 4,
          }}>
          ConsenSys Adventures
        </Atom.Heading>
        <Atom.Flex sx={{flexWrap: 'wrap'}}>
          {questListConsenQuest.map(quest => (
            <QuestCard
              {...quest}
              adventure="consenquest"
              sx={stylesQuestCard}
            />
          ))}
        </Atom.Flex>
      </Atom.Box>
    </>
  );
};
const MillionDevsQuestList = props => {
  return (
    <>
      <Atom.Box>
        <Atom.Heading
          as="h3"
          xxl
          sx={{
            color: 'blue',
            textAlign: 'center',
            py: 4,
          }}>
          DevQuest
        </Atom.Heading>
        <Atom.Flex between>
          {questListMillionDevs.map(quest => (
            <QuestCard {...quest} adventure="devquest" sx={stylesQuestCard} />
          ))}
        </Atom.Flex>
      </Atom.Box>
    </>
  );
};

const SearchEngineOptimization = props => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>EthQuest - Adventure of A Lifetime</title>
      <meta property="og:url" content="http://ethquest.io" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="EthQuest - Adventure of A Lifetime" />
      <meta
        property="og:description"
        content="Experience the Ethereum blockchain with quests and adventures."
      />
    </Helmet>
  );
};

export default PageHome;
